<template>
  <v-navigation-drawer
    :value="isAddNewRefundSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 550 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-refund-sidebar-active', val)"
  >
    <v-dialog
      v-model="isDialogVisible"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Do you continue?
        </v-card-title>
        <v-card-text>
          A new refund will be created which will remain on Pending status
          until its processing.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            :disabled="loading"
            @click="isDialogVisible = false"
          >
            No
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            :disabled="loading"
            @click="onAgree"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <div class="drawer-header d-flex align-center mb-4">
        <span
          class="font-weight-semibold text-base text--primary"
        >Create New Refund</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-refund-sidebar-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-card
            class="mx-auto px-2 mb-5"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  SEARCH THE PAYMENT TO BE REFUNDED
                </div>

                <v-text-field
                  v-model="refundData.paymentCode"
                  :rules="[validators.required]"
                  placeholder="Payment UID"
                  :readonly="paymentFoundModel !== null"
                  single-line
                  hide-details
                  dense
                  outlined
                  :clearable="paymentFoundModel !== null"
                  class="mb-4 me-3"
                  :append-icon="paymentFoundModel === null ? icons.mdiMagnify : ''"
                  @keyup.enter="onSearchPaymentHandler"
                  @click:append="onSearchPaymentHandler"
                  @click:clear="onClearPaymentFoundHandler"
                >
                  <template #label>
                    <span class="red--text"><strong>* </strong></span>Payment UID
                  </template>
                </v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-card-text
              v-if="paymentFoundModel != null"
              class="text--primary"
            >
              <div>Created On: {{ formatDateAndTime(paymentFoundModel.creationDate) }}</div>
              <div>Amount: $ {{ formatCurrency(paymentFoundModel.amount) }}</div>
              <div>Available for refunds: $ {{ formatCurrency(paymentFoundModel.amount - paymentFoundModel.totalRefundsAmount) }}</div>
              <div>Customer: {{ paymentFoundModel != null && paymentFoundModel.customer != null ? paymentFoundModel.customer.firstName + ' ' + paymentFoundModel.customer.lastName : '' }}</div>
            </v-card-text>
          </v-card>

          <v-card
            class="mx-auto px-2 mb-5"
            outlined
          >
            <v-list-item
              three-line
              :disabled="paymentFoundModel === null"
            >
              <v-list-item-content>
                <div class="text-overline mb-4">
                  REFUND DETAILS
                </div>

                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="refundData.amount"
                    outlined
                    dense
                    :rules="[validators.required, validators.decimalValidator]"
                    type="number"
                    placeholder="Amount"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Amount
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="refundData.notificationUrl"
                    outlined
                    dense
                    label="Notification Url"
                    placeholder="Notification Url"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="refundData.referenceCode"
                    outlined
                    dense
                    label="Merchant Reference"
                    placeholder="Merchant Reference"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="refundData.comments"
                    :rules="[validators.required]"
                    outlined
                    dense
                    placeholder="Comments"
                    hide-details="auto"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Comments
                    </template>
                  </v-text-field>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-card>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
            :disabled="!valid || paymentFoundModel === null"
          >
            Submit
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetRefundData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar.isSnackbarVisible"
      class="mt-0 pt-0"
      :timeout="-1"
      top
      centered
      multi-line
      color="red accent-2"
      tile
    >
      <span v-html="snackbar.snackbarMessage"></span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar.isSnackbarVisible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiMagnify } from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
  required,
  emailValidator,
  decimalValidator,
} from '@core/utils/validation'
import {
  formatDateAndTime,
  formatCurrency,
} from '@core/utils/filter'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewRefundSidebarActive',
    event: 'update:is-add-new-refund-sidebar-active',
  },
  props: {
    isAddNewRefundSidebarActive: {
      type: Boolean,
      required: true,
    },
    shopFilterItems: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(false)
    const form = ref(null)
    const paymentFoundModel = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const blankRefundData = {
      paymentCode: '',
      amount: '',
      notificationUrl: '',
      referenceCode: '',
      comments: '',
    }

    const refundData = ref(JSON.parse(JSON.stringify(blankRefundData)))
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const snackbar = ref({
      isSnackbarVisible: false,
      snackBarColor: 'info',
      snackbarMessage: '',
      snackbarTimeOut: '3000',
    })

    const showSnackbarMessage = data => {
      let msg = data.message
      if (data.type === 'error') {
        msg = '<p class="red--text">ERROR:</p>'
        data.message.forEach(x => {
          msg += `<li>${x.message}</li>`
        })
      }
      snackbar.value.isSnackbarVisible = true
      snackbar.value.snackBarColor = data.type
      snackbar.value.snackbarMessage = msg
    }

    const resetRefundData = () => {
      paymentFoundModel.value = null
      refundData.value = JSON.parse(JSON.stringify(blankRefundData))
      resetForm()
      emit('update:is-add-new-refund-sidebar-active', false)
    }

    const onClearPaymentFoundHandler = () => {
      paymentFoundModel.value = null
      refundData.value.amount = null
    }

    const onSubmit = () => {
      if (valid.value) {
        isDialogVisible.value = true
      } else {
        validate()
      }
    }

    const onSearchPaymentHandler = () => {
      if (refundData.value.paymentCode === '') return

      loading.value = true

      store
        .dispatch('processing-refunds/fetchPaymentToBeRefunded', {
          paymentCode: refundData.value.paymentCode,
        })
        .then(response => {
          paymentFoundModel.value = response.data === '' ? null : response.data

          if (paymentFoundModel.value !== null) {
            const amountToBeRefunded = formatCurrency(paymentFoundModel.value.amount - paymentFoundModel.value.totalRefundsAmount)
            refundData.value.amount = amountToBeRefunded
          }
        })
        .catch(error => {
          showSnackbarMessage({ type: 'error', message: error.response.data.errors })

          return error
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onAgree = () => {
      loading.value = true

      store
        .dispatch('processing-refunds/addRefund', refundData.value)
        .then(response => {
          emit('refetch-data')
          emit('update:is-add-new-refund-sidebar-active', false)
          emit('refund-saved', response.data)
          resetRefundData()
        })
        .catch(e => {
          isDialogVisible.value = false
          showSnackbarMessage({ type: 'error', message: e.response.data.errors })

          return e
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      snackbar,
      refundData,
      isDialogVisible,
      valid,
      loading,
      paymentFoundModel,
      resetRefundData,
      showSnackbarMessage,
      onAgree,
      onSubmit,
      onSearchPaymentHandler,
      onClearPaymentFoundHandler,
      validate,
      formatDateAndTime,
      formatCurrency,

      // validation
      validators: { required, emailValidator, decimalValidator },
      icons: {
        mdiClose,
        mdiMagnify,
      },
    }
  },
}
</script>
