import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import periodType from '@/constants/period-type'
import refundStatus from '@/constants/refund-status'
import ability from '@/plugins/acl/ability'

export default function usePaymentList() {
  const refundsTableColumns = [
    {
      text: 'UID', value: 'refundCode', sortable: false, width: 220,
    },
    {
      text: 'CREATED ON', value: 'createdAt', align: 'center', width: 150,
    },
    {
      text: 'SHOP',
      value: 'shopName',
      sortable: false,
      align: 'center',
      width: 250,
    },
    {
      text: 'AMOUNT',
      value: 'amount',
      sortable: false,
      align: 'center',
      width: 150,
    },
    {
      text: 'STATUS',
      value: 'refundStatus',
      sortable: false,
      align: 'center',
      width: 180,
    },
    {
      text: 'PAYMENT UID', value: 'paymentCode', sortable: false, width: 220,
    },
    {
      text: 'ORDER ID', value: 'orderId', sortable: false, width: 220,
    },
    {
      text: 'CUSTOMER',
      value: 'customer',
      sortable: false,
      align: 'center',
      width: 100,
    },
    {
      text: 'COMMENTS',
      value: 'comments',
      sortable: false,
      align: 'center',
      width: 200,
    },
  ]

  const canCreate = ref(ability.can('create', 'refunds'))

  const showDateRange = ref(false)

  const refundItems = ref([])
  const refundItemsLength = ref(0)

  const searchFilterModel = ref('')

  const shopFilterModel = ref(null)
  const shopFilterItems = ref([])

  const statusFilterModel = ref(null)
  const statusFilterItems = ref([])

  const dateFilterModel = ref([
    new Date().toISOString().substring(0, 10),
    new Date().toISOString().substring(0, 10),
  ])

  const periodTypeItems = ref([])
  const periodTypeModel = ref(null)

  const loading = ref(false)

  const options = ref({
    sortBy: ['r.createdAt'],
    sortDesc: [true],
  })

  const snackbar = ref({
    isSnackbarVisible: false,
    snackBarColor: 'info',
    snackbarMessage: '',
    snackbarTimeOut: '3000',
  })

  const showSnackbarMessage = data => {
    let msg = data.message
    if (data.type === 'error') {
      msg = '<p class="red--text">ERROR:</p>'
      data.message.forEach(x => {
        msg += `<li>${x.message}</li>`
      })
    }
    snackbar.value.isSnackbarVisible = true
    snackbar.value.snackBarColor = data.type
    snackbar.value.snackbarMessage = msg
  }

  const menuDateRange = ref(false)

  const fetchList = () => {
    loading.value = true
    store
      .dispatch('processing-refunds/fetchList', {
        search: searchFilterModel.value,
        options: options.value,
        statusCode: statusFilterModel.value,
        shopId: shopFilterModel.value,
        periodType: periodTypeModel.value,
        from: dateFilterModel.value[0],
        to: dateFilterModel.value[1],
      })
      .then(response => {
        const { records, count } = response.data
        refundItems.value = records
        refundItemsLength.value = count
      })
      .catch(error => error)
      .finally(() => {
        loading.value = false
      })
  }

  const onSearch = () => {
    if (searchFilterModel.value === '') return
    fetchList()
  }

  const fetchConfig = () => {
    loading.value = true
    store
      .dispatch('processing-refunds/fetchConfig')
      .then(response => {
        const config = response.data
        shopFilterItems.value = config.shops
        statusFilterItems.value = config.refundStatus
        periodTypeItems.value = config.periodTypes
        periodTypeModel.value = periodTypeItems.value.find(
          x => x.value === periodType.DAY,
        ).value
      })
      .catch(error => error)
      .finally(() => {
        loading.value = false
      })
  }

  const downloadList = () => {
    loading.value = true
    store
      .dispatch('processing-refunds/export', {
        search: searchFilterModel.value,
        options: options.value,
        statusCode: statusFilterModel.value,
        shopId: shopFilterModel.value,
        periodType: periodTypeModel.value,
        from: dateFilterModel.value[0],
        to: dateFilterModel.value[1],
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileName = response.headers.filename
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      })
      .catch(error => error)
      .finally(() => {
        loading.value = false
      })
  }

  const onRefundSaved = message => {
    snackbar.value.isSnackbarVisible = true
    snackbar.value.snackBarColor = 'info'
    snackbar.value.snackbarMessage = `Your refund: <strong>${message}</strong> has been created successfully!`
  }

  const onRefundError = errors => {
    snackbar.value.isSnackbarVisible = true
    snackbar.value.snackBarColor = 'error'

    let msg = ''
    errors.forEach(element => {
      msg += `<li>${element.message}</li>`
    })

    snackbar.value.snackbarMessage = `Error: ${msg}`
  }

  const onPeriodTypeChange = () => {
    showDateRange.value = periodTypeModel.value === periodType.CUSTOM
  }

  watch([options], () => {
    fetchList()
  })

  const applyFilters = () => {
    options.value.page = 1
    searchFilterModel.value = null
    fetchList()
  }

  const resetFilters = () => {
    searchFilterModel.value = ''
    shopFilterModel.value = null
    statusFilterModel.value = null
    periodTypeModel.value = periodTypeItems.value.find(x => x.value === periodType.DAY).value
    showDateRange.value = false
    dateFilterModel.value = [
      new Date().toISOString().substring(0, 10),
      new Date().toISOString().substring(0, 10),
    ]
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveStatusVariant = status => {
    switch (status) {
      case refundStatus.PENDING:
        return 'refund-status--pending'
      case refundStatus.REFUNDED:
        return 'refund-status--paid'
      case refundStatus.DECLINED:
        return 'refund-status--expired'
      default:
        return 'refund-status--pending'
    }
  }

  return {
    refundItems,
    refundItemsLength,
    refundsTableColumns,
    searchFilterModel,
    shopFilterItems,
    shopFilterModel,
    statusFilterModel,
    statusFilterItems,
    periodTypeModel,
    periodTypeItems,
    dateFilterModel,
    menuDateRange,
    loading,
    options,
    showDateRange,
    snackbar,
    canCreate,
    showSnackbarMessage,
    onRefundSaved,
    onRefundError,
    applyFilters,
    resetFilters,
    fetchList,
    fetchConfig,
    onSearch,
    downloadList,
    resolveStatusVariant,
    onPeriodTypeChange,
  }
}
